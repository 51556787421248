const scanQrReducer = (
  state = {
    loading: false,
    stepperState: {
      clinic: false,
      patient: false,
    },
    scannerState: null,
    currentData: null,
    isTimeExpired: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_SCAN_QR_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_STEPPERS_STATE":
      return {
        ...state,
        stepperState: {
          ...state.stepperState,
          ...action.payload.data,
        },
      };

    case "SET_TIME_EXPIRED":
      return {
        ...state,
        isTimeExpired: action.payload.data,
      };

    case "SET_CURRENT_DATA":
      return {
        ...state,
        currentData: action.payload.data,
      };

    case "RESET":
      return {
        loading: false,
        stepperState: null,
        scannerState: null,
        currentData: null,
        isTimeExpired: null,
      };

    default:
      return state;
  }
};
export default scanQrReducer;
