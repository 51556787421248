import { getApp, getApps, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  initializeFirestore,
} from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

//Firebase configuration

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}

export let database = initializeFirestore(getApp(), {
  experimentalAutoDetectLongPolling: true,
});

export let auth = getAuth();
export const switchBucket = (bucketName) => {
  if (process.env.REACT_APP_STAGING === "local") {
    connectStorageEmulator(getStorage(getApp(), bucketName), "localhost", 9199);
  }
  return getStorage(getApp(), bucketName);
};

if (process.env.REACT_APP_STAGING === "local") {
  connectAuthEmulator(auth, "http://localhost:9099", {
    disableWarnings: true,
  });
  connectFirestoreEmulator(database, "localhost", 8080);
} else {
  if (!window.Cypress) {
    try {
      enableIndexedDbPersistence(database, { synchronizeTabs: true });
    } catch (error) {
      if (error.code === "failed-precondition") {
        console.error(error.code);
      } else if (error.code === "unimplemented") {
        console.error(error.code);
      } else {
        console.error(error);
      }
    }
  }
}
