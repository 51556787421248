import store from "../store/store";
import { put, takeEvery, all } from "redux-saga/effects";
import { statusActions } from "./statusSaga";
import { bucketNames, getFileUrl } from "../../services/storage";

export const actionTypes = {
  SET_CONNECTIONS: "SET_CONNECTIONS",
  SET_CURRENT_CONNECTION: "SET_CURRENT_CONNECTION",
  GET_BRAND_LOGO: "GET_BRAND_LOGO",
  GET_DOWNLOADABLE_URL: "GET_DOWNLOADABLE_URL",
};

export const connectionActions = {
  setConnections: (data) => {
    store.dispatch({
      type: actionTypes.SET_CONNECTIONS,
      payload: {
        data: data,
      },
    });
  },

  setCurrentConnection: (data) => {
    store.dispatch({
      type: actionTypes.SET_CURRENT_CONNECTION,
      payload: {
        data: data,
      },
    });
  },

  getBrandLogo: (data) => {
    store.dispatch({
      type: actionTypes.GET_BRAND_LOGO,
      payload: {
        data: data,
      },
    });
  },

  getBrandLogoURL: (data) => {
    store.dispatch({
      type: actionTypes.GET_DOWNLOADABLE_URL,
      payload: {
        data: data,
      },
    });
  },
};

function* setConnectionsWorker(action) {
  try {
    yield setConnectionsLoading(true);
    yield put({
      type: "SET_CONNECTIONS_DATA",
      payload: {
        data: action.payload.data,
      },
    });
    yield setConnectionsLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* setCurrentConnectionWorker(action) {
  try {
    yield setConnectionsLoading(true);
    yield put({
      type: "SET_CURRENT_CONNECTION_DATA",
      payload: {
        data: action.payload.data,
      },
    });
    yield setConnectionsLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* getBrandLogoWorker(action) {
  try {
    const brandIds = action.payload.data.filter(
      (item, index) => action.payload.data.indexOf(item) === index
    );

    for (let data of Object.values(brandIds)) {
      if (
        store.getState().connection.brandLogo &&
        Object.keys(store.getState().connection.brandLogo).includes(data)
      ) {
        return;
      }
      connectionActions.getBrandLogoURL(data);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

function* getBrandLogoURLWorker(action) {
  try {
    const data = action.payload.data;

    let URL;

    URL = yield getFileUrl(
      `gs://${bucketNames.nintoProfilePictures}/clinicBrands/${data}.png`
    );

    yield put({
      type: "SET_CONNECTIONS_BRAND_LOGO",
      payload: {
        data: {
          [data]: URL,
        },
      },
    });
  } catch (error) {
    if (error.toString().includes("does not exist")) {
      console.error("Unable to download clinic logo");
    } else {
      yield statusActions.setErrorStatus(error);
    }
  }
}

export function* connectionWatcher() {
  yield all([takeEvery("SET_CONNECTIONS", setConnectionsWorker)]);
  yield all([takeEvery("SET_CURRENT_CONNECTION", setCurrentConnectionWorker)]);
  yield all([takeEvery("GET_BRAND_LOGO", getBrandLogoWorker)]);
  yield all([takeEvery("GET_DOWNLOADABLE_URL", getBrandLogoURLWorker)]);
}

function* setConnectionsLoading(bool) {
  yield put({
    type: "SET_CONNECTION_LOADING",
    payload: {
      loading: bool,
    },
  });
}
