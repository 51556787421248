import { all, put, takeEvery } from "@redux-saga/core/effects";
// import {
//   addConnectionsToProfile,
//   createClinicBrand,
//   createClinicProfile,
//   createProfile,
//   editProfile,
// } from "../../services/database";
// import { throwError } from "../../services/error";
// import { uploadProfilePicture } from "../../services/storage";

import { isValidObject } from "../../utils/validators";
import store from "../store/store";
import { authActions } from "./authSaga";
import { statusActions } from "./statusSaga";

export const actionTypes = {
  SET_ACCOUNT_PROFILE: "SET_ACCOUNT_PROFILE",
  CREATE_CLINIC_PROFILE: "CREATE_CLINIC_PROFILE",
  EDIT_PROFILE: "EDIT_PROFILE",
};

export const profileActions = {
  //create patient profile

  //linked and unlinked profile get actions
  setAccountProfile: (profile) => {
    store.dispatch({
      type: actionTypes.SET_ACCOUNT_PROFILE,
      payload: {
        data: profile,
      },
    });
  },

  // EDIT PROFILE
  editProfile: (data) => {
    store.dispatch({
      type: actionTypes.EDIT_PROFILE,
      payload: {
        data: data,
      },
    });
  },
};

function* setProfilesWorker(action) {
  try {
    yield put({
      type: "SET_PROFILES",
      payload: action.payload.data,
    });

    if (!isValidObject(action.payload.data)) {
      if (typeof store.getState().auth.data.displayName === "string") {
        // yield createProfile(store.getState().auth.data.displayName);
        yield authActions.removeDisplayName();
      } else {
        authActions.logout();
      }
    }
  } catch (error) {
    statusActions.setErrorStatus(error);
  }
}

// edit profile
export function* editProfileWorker(action) {
  try {
    // const profileId =
    //   store.getState().profile.data &&
    //   Object.keys(store.getState().profile.data)[0];

    // let connectionIds = {};
    // store.getState().connection?.data?.forEach((data) => {
    //   connectionIds = { [data.connectionId]: data.connectionId };
    // });

    yield setProfileLoading(true);

    // yield editProfile(
    //   action.payload.data,
    //   profileId,
    //   Object.keys(connectionIds)
    // );

    yield setProfileLoading(false);
    statusActions.setSuccessStatus("Successfully saved");
  } catch (error) {
    statusActions.setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

export function* profileWatcher() {
  yield all([
    takeEvery("SET_ACCOUNT_PROFILE", setProfilesWorker),
    takeEvery("EDIT_PROFILE", editProfileWorker),
  ]);
}

function* setProfileLoading(bool) {
  yield put({
    type: "SET_PROFILE_LOADING",
    payload: {
      loading: bool,
    },
  });
}
