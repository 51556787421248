import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import connectionReducer from "./connectionReducer";
import scanQrReducer from "./scanQrReducer";
import profileReducer from "./profileReducer";
import statusReducer from "./statusReducer";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "profile", "scanQr", "status", "connection"],
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"],
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"],
};

const scanQrPersistConfig = {
  key: "scanQr",
  storage: storage,
  blacklist: ["loading"],
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"],
};

const appPersistConfig = {
  key: "app",
  storage: storage,
  blacklist: [],
};

const connectionPersistConfig = {
  key: "connection",
  storage: storage,
  blacklist: ["loading"],
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  scanQr: persistReducer(scanQrPersistConfig, scanQrReducer),
  connection: persistReducer(connectionPersistConfig, connectionReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
