const profileReducer = (
  state = {
    loading: false,
    data: null,
    profilePicture: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_PROFILE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "SET_PROFILES":
      return {
        ...state,
        data: action.payload,
      };
    case "SET_PROFILE_PICTURE_DATA":
      return {
        ...state,
        profilePicture: action.payload,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        profilePicture: null,
      };
    default:
      return state;
  }
};
export default profileReducer;
