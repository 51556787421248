import { all } from "redux-saga/effects";
import appWatcher from "./appSaga";
import { authWatcher } from "./authSaga";
import { connectionWatcher } from "./connectionSaga";
import { scanQrWatcher } from "./scanQrSaga";
import mockWatcher from "./mockSaga";
import { profileWatcher } from "./profileSaga";
import { statusWatcher } from "./statusSaga";

export default function* rootSaga() {
  yield all([
    authWatcher(),
    profileWatcher(),
    mockWatcher(),
    scanQrWatcher(),
    connectionWatcher(),
    appWatcher(),
    statusWatcher(),
  ]);
}
