import { api } from "../utils/constants";

export const verifyClinic = async (token) => {
  const response = await fetch(`${api.baseUrl}/connectPatient/verifyClinic`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`
    }
  });
  const result = await response.json();
  // if (result.error) {
  //   throw result.error;
  // }
  return result;
};

export const connectPatientToClinic = async (token, bodyData) => {
  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/demographic/request`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${token}`
      },
      body: JSON.stringify(bodyData)
    }
  );
  const result = await response.json();
  return result;
};

export async function findPatientWithHealthId(data, clinicId, accessToken) {
  const response = await fetch(
    `${api.baseUrl}/nintoProfileManagement/findPatient`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        healthId: data,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

//check phone number availability
export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${
      api.baseUrl
    }/nintoProfileManagement/accountExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}&type=clinicStaff`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}
