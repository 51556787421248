import store from "../store/store";
import { put, takeEvery, all, takeLeading } from "redux-saga/effects";
import { statusActions } from "./statusSaga";
import {
  connectPatientToClinic,
  findPatientWithHealthId
} from "../../services/api";
import { throwError } from "../../services/error";

export const actionTypes = {
  SET_STEPPER_STATE: "SET_STEPPER_STATE",
  SET_ON_QR_DETECTED: "SET_ON_QR_DETECTED",
  SET_IS_TIME_EXPIRED: "SET_IS_TIME_EXPIRED"
};

export const scanQrActions = {
  setStepperState: (data) => {
    store.dispatch({
      type: actionTypes.SET_STEPPER_STATE,
      payload: {
        data: data
      }
    });
  },

  setOnQrDetected: (data) => {
    store.dispatch({
      type: actionTypes.SET_ON_QR_DETECTED,
      payload: {
        data: data
      }
    });
  },

  setIsTimeExpired: (data) => {
    store.dispatch({
      type: actionTypes.SET_IS_TIME_EXPIRED,
      payload: {
        data: data
      }
    });
  }
};

function* setStepperStateWorker(action) {
  try {
    yield setScanQrLoading(true);
    yield put({
      type: "SET_STEPPERS_STATE",
      payload: {
        data: action.payload.data
      }
    });
    yield setScanQrLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setScanQrLoading(false);
  }
}

function* setOnQrDetectedWorker(action) {
  try {
    if (store.getState().scanQr.stepperState.patient === false) {
      yield setScanQrLoading(true);

      const patientDetailsFromQrCode = JSON.parse(action.payload.data);

      const connectionId = store.getState().connection.currentConnection;

      const dob = patientDetailsFromQrCode.dob.split("-");

      const alteredDob = `${dob[2]}-${dob[1]}-${dob[0]}`;

      const findPatientWithHealthIdResponse = yield findPatientWithHealthId(
        patientDetailsFromQrCode.phr,
        connectionId,
        store.getState().auth.data.accessToken
      );

      const connectPatientToClinicResult = yield connectPatientToClinic(
        store.getState().auth.data.accessToken,
        {
          name: patientDetailsFromQrCode.name,
          gender: patientDetailsFromQrCode.gender,
          dateOfBirth: alteredDob,
          patientId:
            findPatientWithHealthIdResponse?.data?.findResults[0]?.patientId,
          clinicId: connectionId
        }
      );

      if (connectPatientToClinicResult.success === true) {
        statusActions.setSuccessStatus("Connected successfully");
      } else {
        throw throwError("custom", "Something went wrong");
      }

      yield put({
        type: "SET_STEPPERS_STATE",
        payload: {
          data: {
            patient: true
          }
        }
      });

      yield setScanQrLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield put({
      type: "SET_SCANNER_STATUS",
      payload: {
        data: "error"
      }
    });
    yield setScanQrLoading(false);
  }
}

function* setIsTimeExpiredWorker(action) {
  try {
    yield setScanQrLoading(true);
    yield put({
      type: "SET_TIME_EXPIRED",
      payload: {
        data: action.payload.data
      }
    });
    yield setScanQrLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setScanQrLoading(false);
  }
}

export function* scanQrWatcher() {
  yield all([
    takeEvery("SET_STEPPER_STATE", setStepperStateWorker),
    takeLeading("SET_ON_QR_DETECTED", setOnQrDetectedWorker),
    takeEvery("SET_IS_TIME_EXPIRED", setIsTimeExpiredWorker)
  ]);
}

function* setScanQrLoading(bool) {
  yield put({
    type: "SET_SCAN_QR_LOADING",
    payload: {
      loading: bool
    }
  });
}
