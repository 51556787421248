import { query, where, collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { statusActions } from "../redux/sagas/statusSaga";
import { profileActions } from "../redux/sagas/profileSaga";
import { isValidObject } from "../utils/validators";
import { connectionActions } from "../redux/sagas/connectionSaga";
import { database } from "./firebase";

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null,
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "clinicStaffs"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        // { includeMetadataChanges: true },
        (dataSnapshot) => {
          let profiles = {};
          // let isOnline = true;

          dataSnapshot.forEach((doc) => {
            profiles[doc.id] = {
              ...doc.data(),
            };
          });
          profileActions.setAccountProfile(profiles);
        },
        (error) => {
          statusActions.setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuthed === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber),
      });
    } else if (
      props.isAuthed === false &&
      typeof props.phoneNumber !== "string" &&
      typeof props.uid !== "string" &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber, props.uid]);
}

//connection Listener

export async function useConnectionsListener(props) {
  const [connectionsListener, setConnectionsListener] = useState({
    listener: null,
  });

  const subscribeToDocuments = (phoneNumber, uid) => {
    if (phoneNumber) {
      const connectionsQuery = query(
        collection(database, "clinicStaffs", uid, "connections"),
        where("phoneNumber", "==", phoneNumber)
        // where("verified", "==", true)
      );
      return onSnapshot(
        connectionsQuery,
        (querySnapshot) => {
          let connections = [];
          let brandData = [];
          querySnapshot.forEach((doc) => {
            if (isValidObject(doc.data())) {
              brandData.push(doc.data().brandId);
              connections.push({ connectionId: doc.id, ...doc.data() });
            }
          });
          connectionActions.getBrandLogo(brandData);
          connectionActions.setConnections(connections);
        },
        (error) => {
          statusActions.setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (props.isAuthed === true && props.uid && props.phoneNumber) {
      setConnectionsListener({
        listener: subscribeToDocuments(props.phoneNumber, props.uid),
      });
    } else if (
      props.isAuthed === false &&
      typeof connectionsListener.listener === "function"
    ) {
      connectionsListener.listener();
      setConnectionsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.uid, props.isAuthed, props.phoneNumber]);
}
