import {
  getAuth,
  onAuthStateChanged,
  RecaptchaVerifier,
  signOut,
  updateProfile,
  deleteUser,
  PhoneAuthProvider,
  signInWithCredential,
  connectAuthEmulator,
} from "firebase/auth";
// import LogRocket from "logrocket";
import { authActions } from "../redux/sagas/authSaga";
import { getApps, initializeApp } from "firebase/app";
import { useEffect, useRef } from "react";
import { auth } from "./firebase";

//Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}

if (process.env.REACT_APP_STAGING === "local") {
  connectAuthEmulator(auth, "http://localhost:9099", {
    disableWarnings: true,
  });
}

//invisible recaptcha verifier
export function reCaptchaVerifier() {
  if (window.Cypress && window.location.hostname === "localhost") {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  return new RecaptchaVerifier(
    "login-button",
    {
      size: "invisible",
    },
    auth
  );
}

export async function otpRequest(phoneNumber) {
  const appVerifier = window.reCaptchaVerifier;
  const authProvider = new PhoneAuthProvider(auth);
  const verificationId = await authProvider.verifyPhoneNumber(
    phoneNumber,
    appVerifier
  );
  return verificationId;
}

export function getCurrentUser() {
  return auth.currentUser;
}

export async function otpValidate(loginOtp, verificationId) {
  const authCredentials = PhoneAuthProvider.credential(
    verificationId,
    loginOtp
  );
  return authCredentials;
}

export async function signInWithAuthCredentials(authCredentials) {
  const response = await signInWithCredential(auth, authCredentials);
  return response;
}

//SET USER DISPLAY NAME
export async function setDisplayName(name) {
  await updateProfile(auth.currentUser, {
    displayName: name,
  });
}

//signup using auth credentials
export async function signUpWithAuthCredentials(authCredentials, name) {
  const response = await signInWithCredential(auth, authCredentials);
  if (response.user.displayName === null) {
    const displayName = {
      clinicStaff: name,
      corporateStaff: null,
      employee: null,
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName),
    });
  } else {
    let otherDisplayNames;
    try {
      otherDisplayNames = JSON.parse(response.user.displayName);
    } catch (error) {
      otherDisplayNames = {};
    }
    const displayName = {
      ...otherDisplayNames,
      clinicStaff: name,
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName),
    });
  }
  return response;
}

//auth subscriber
export function useAuthSubscriber() {
  const isAuthed = useRef(false);
  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (isAuthed.current === false) {
          isAuthed.current = true;
          // LogRocket.identify(user.uid, {
          //   phoneNumber: user.phoneNumber
          // });
        }
      } else {
        if (isAuthed.current === true) {
          isAuthed.current = false;
        }
        // authActions.logout();
      }
    });
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const accessToken = await user.getIdToken();
        authActions.addAccessToken(accessToken);
      }
    });
    // eslint-disable-next-line
  }, []);
  return isAuthed.current;
}

//logout subscriber
export const logout = async () => {
  await signOut(auth);
};

export async function deleteUserAccount() {
  await deleteUser(auth.currentUser);
}
